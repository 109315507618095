import { FileNode } from "gatsby-plugin-image/dist/src/components/hooks"
import { getImage } from "gatsby-plugin-image"

type BlogData = {
  node: {
    Publish_Date?: any
    slug?: string | null | undefined
    title?: string | null | undefined
    article_type?: { slug?: string | null | undefined } | null | undefined
  }
}[]

/**
 * Converts strapiArticle data into data for the FeaturedPosts component.
 * @param  blogData - graphql query for three articles from strapiArticles
 * @returns {Object} ex: {
 *  title: string
 *  date: string
 *  srcUrl: string
 * }
 */
export const convertArticleData = (blogData: BlogData) => {
  const data = blogData.map((article: any) => ({
    title: article.node.title,
    date: article.node.Publish_Date,
    srcUrl: `/news/${article.node.article_type?.slug}/${article.node.slug}`,
  }))
  return data
}

/**
 * Converts Image path to the correct path
 * @param strapiImageUrl strapi image url
 * @returns String
 */
export const getStrapiCorrectImageSrc = (strapiImageUrl: string) => {
  const domain = process.env.GATSBY_API_URL
  return `${domain}${strapiImageUrl}`
}

/**
 * Replaces the current query string value with the new provided value
 * without triggering a page refresh.
 * @param qsValue - search query string
 */
export const setQueryStringWithoutPageReload = (qsValue: string) => {
  let hash = ""
  if (window.location.hash) {
    hash = window.location.hash
  }
  const newURL =
    window.location.protocol +
    "//" +
    window.location.host +
    window.location.pathname +
    qsValue +
    hash

  window.history.pushState({ path: newURL }, "", newURL)
}

/**
 * Creates URL search params from object
 * @param queryStringObj - object of search parameters
 * @returns {String}
 */
export const createQueryString = (queryStringObj: any) => {
  const queryString = new URLSearchParams(queryStringObj)
  return queryString
}

/**
 * Gets a URL's search parameters and transforms them into an object
 * @param queryString - a URL search
 * @returns {Object}
 */
export const getQueryStringObj = (
  queryString: string = window.location.search
) => {
  const urlSearchParams = new URLSearchParams(queryString)
  const params = Object.fromEntries(urlSearchParams.entries())
  return params
}

export interface Image {
  localFile: FileNode
}

// ImageDataLike

export const getRemoteImage = (
  sourceImage: { url: string },
  images: [Image]
) => {
  let returnImage = null
  let image = images.find(image => {
    return (
      image?.localFile?.url ===
      `${process.env.GATSBY_API_URL}${sourceImage.url}`
    )
  })

  if (!image) return undefined

  return getImage(image.localFile)
}
