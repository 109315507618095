/** @jsxRuntime classic */
/** @jsx jsx */
import { graphql, PageProps } from "gatsby"
import { IGatsbyImageData } from "gatsby-plugin-image"
import { Box, jsx } from "theme-ui"

import type { MarketingAssetsPageQuery } from "../../graphql-types"
import BackgroundSVG from "../images/icons/background-light.svg"

import MarketingAssetsBlockMapper from "components/block-mappers/MarketingAssetsBlockMapper"
import Layout from "components/Layout"
import PageHero from "components/PageHero"

export const query = graphql`
  query MarketingAssetsPage {
    strapiMarketingAssets {
      blocks
      seo {
        metaTitle
        metaDescription
        shareImage {
          localFile {
            publicURL
          }
        }
      }
      Hero {
        hero_links {
          link_button_url
          link_button_text
        }
        title
        content
        has_lines
        has_locator
        image {
          alternativeText
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        image_position
        subtitle
      }
    }
  }
`

interface MarketingAssetsPageProps extends PageProps {
  data: MarketingAssetsPageQuery
}

const MarketingAssetsPage = ({ data, ...props }: MarketingAssetsPageProps) => {
  return (
    <Layout
      seo={data.strapiMarketingAssets?.seo}
      location={props.location}
      marketingEmailSignUp={true}
    >
      <Box sx={{ position: "relative" }}>
        <BackgroundSVG
          sx={{
            position: "absolute",
            zIndex: -1,
            transformOrigin: "center",
            bottom: 0,
            right: 0,
            width: "auto",
            height: "100%",
            transform: "translate(30%, 0)",
            "& > g": {
              opacity: 0.2,
            },
            "& *": {
              fill: "#d6d1c4cf",
            },
          }}
        />
        <PageHero
          title={"Marketing Tools and Assets" as string}
          subtitle={data.strapiMarketingAssets?.Hero?.subtitle as string}
          content={
            "Each of the toolkits offers easy-to-download, AGUILA-branded assets for official 2023 customer use." as string
          }
          hasLines={true as boolean}
          hasLocatorCTA={false as boolean}
          imagePosition={
            data.strapiMarketingAssets?.Hero?.image_position as string
          }
          image={
            data.strapiMarketingAssets?.Hero?.image?.localFile?.childImageSharp
              ?.gatsbyImageData as IGatsbyImageData
          }
          imageAlt={data.strapiMarketingAssets?.Hero?.image?.alternativeText}
          buttonOneUrl={
            data.strapiMarketingAssets?.Hero?.hero_links?.[0]
              ?.link_button_url as string
          }
          buttonOneText={
            data.strapiMarketingAssets?.Hero?.hero_links?.[0]
              ?.link_button_text as string
          }
          buttonTwoUrl={
            data.strapiMarketingAssets?.Hero?.hero_links?.[1]
              ?.link_button_url as string
          }
          buttonTwoText={
            data.strapiMarketingAssets?.Hero?.hero_links?.[1]
              ?.link_button_text as string
          }
          sx={{
            ".titlebox": {
              maxWidth: ["70%", null, "610px"],
              mr: [0, null, 16],
            },
          }}
        />

        {data.strapiMarketingAssets?.blocks.map((block: any, index: number) => (
          <MarketingAssetsBlockMapper block={block} key={index} />
        ))}
      </Box>
    </Layout>
  )
}

export default MarketingAssetsPage
